import React from 'react'
import Image from 'next/image'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import CustomLink from 'components/Link/CustomLink'

import style from './Post.module.scss'

import getWordpressCloudinaryId from 'utils/strings/getWordpressCloudinaryId'
import { formatPostDate } from 'utils/Dates'
import {
  getCategoryLink,
  getCategorySlug,
  getDisplayCategory,
} from 'utils/Blog'
import cloudinaryLoader from 'utils/imageLoaders/cloudinaryLoader'
import { preserveAndApplyQuery } from 'utils/Strings'

type BlogPostCardProps = {
  data: {
    categories: number[]
    formatted_categories: string[]
    slug: string
    renderedTitle: string
    featured_image_url?: string
    date: string
    excerpt: { rendered: string }
  }
  categoryData: unknown
}

const BlogPostCard: React.FC<BlogPostCardProps> = ({
  data: {
    categories,
    formatted_categories,
    slug,
    renderedTitle,
    featured_image_url,
    date,
    excerpt,
  },
  categoryData,
}) => {
  const router = useAugmentedRouter()
  const category = getDisplayCategory(categories, formatted_categories)
  const categorySlug = getCategorySlug(categoryData, category)

  return (
    <div className={style.post}>
      <CustomLink
        className={`${style.postLink} post-link`}
        href={preserveAndApplyQuery(
          `/blog/${categorySlug}/${slug}`,
          router.query,
        )}
        title={renderedTitle}
      >
        {' '}
      </CustomLink>
      {featured_image_url && getWordpressCloudinaryId(featured_image_url) ? (
        <div className={`image is-16by9 ${style.featuredImage}`}>
          <Image
            alt={renderedTitle}
            fill
            loader={cloudinaryLoader}
            sizes="(min-width: 1024px) calc(((100vw - 10rem) / 3) - 1rem), (min-width: 768px) calc(((100vw - 6rem) / 2) - 1rem), calc(100vw - 6rem)"
            src={getWordpressCloudinaryId(featured_image_url)!}
            style={{
              objectFit: 'cover',
            }}
          />
        </div>
      ) : (
        featured_image_url?.includes(
          process.env.NEXT_PUBLIC_BASE_WP_API_URL!,
        ) && (
          <div className={`image is-16by9 ${style.featuredImage}`}>
            <Image
              alt={renderedTitle}
              fill
              sizes="(min-width: 1024px) calc(((100vw - 10rem) / 3) - 1rem), (min-width: 768px) calc(((100vw - 6rem) / 2) - 1rem), calc(100vw - 6rem)"
              src={featured_image_url}
              style={{
                objectFit: 'cover',
              }}
            />
          </div>
        )
      )}
      <div className={style.cardContent}>
        <div>
          <div className={style.date}>{formatPostDate(date)}</div>
          <h3 className={style.title}>{renderedTitle}</h3>
          {excerpt && (
            <div
              className={style.excerpt}
              dangerouslySetInnerHTML={{ __html: excerpt.rendered }}
            />
          )}
        </div>
        <div className={style.category}>
          {category ? (
            <CustomLink
              className="category-link"
              href={getCategoryLink(categorySlug)}
              prefetch={false}
              title={category.name}
            >
              {category.name}
            </CustomLink>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default BlogPostCard
