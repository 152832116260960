import React from 'react'

import Checkbox from './Checkbox'
import style from './CheckboxList.module.scss'

const CheckboxList = (props) => {
  const { id, values, selections, onChange, error, errorLabel } = props

  return (
    <div className={style.control}>
      {values && values.length ? (
        <ul>
          {values.map(({ label, disabled }, i) => {
            return (
              <li key={i}>
                <Checkbox
                  checked={selections.indexOf(label) > -1}
                  disabled={disabled}
                  id={`${id}-${label}`}
                  label={label}
                  name={label}
                  onChange={(value) => onChange(value)}
                  value={label}
                />
              </li>
            )
          })}
        </ul>
      ) : null}
      {error && errorLabel && (
        <span className="formInputErrorMsg">*{errorLabel}</span>
      )}
    </div>
  )
}

export default CheckboxList
