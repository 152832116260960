import React, { useState, useMemo } from 'react'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import FormInput from 'components/Forms/FormInput'
import CheckboxList from 'components/Forms/CheckboxList'

import CtaLayout from './CtaLayout'

import { hubspotForms } from 'config/Blog'

import { getCookieByName, preserveAndApplyQuery } from 'utils/Strings'
import { handleCtaButtonLink, handleCtaGtm } from 'utils/Blog'

import type { CtaData } from 'types/blogData'

type HubspotCtaProps = {
  pageName: string
  cta: CtaData
  fullWidth: boolean
}

const HubspotCta: React.FC<HubspotCtaProps> = ({
  pageName,
  cta,
  fullWidth,
}) => {
  const router = useAugmentedRouter()
  const searchParams = router.query

  // Define individual forms for input/POST data checks
  const {
    guestSubscription,
    guestSubscriptionTest,
    ownerSubscription,
    ownerSubscriptionTest,
    qualifyButton,
    qualifyButtonTest,
    checklistDownload,
    successGuide,
    marketPerformance,
    suspectContent,
    ownerInsight,
    sustainabilityDownload,
    homeInventoryDownload,
    vrIn2022Download,
    hvcoDownload,
    mainentanceCheckListDownload,
    // Not defining nextStay because it isn't used anywhere
  } = hubspotForms

  // Find form to display
  const currentForm = useMemo(
    () =>
      hubspotForms[
        Object.keys(hubspotForms).find(
          (key) =>
            hubspotForms[key].id === cta.form_config?.hubspot_config?.form_id,
        ) ?? ''
      ] ?? { id: 'form-not-found', hasUtm: false },
    [cta.form_config?.hubspot_config?.form_id],
  )

  // Create unique field id
  const emailId = `email-${currentForm.id}`

  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessages, setErrorMessages] = useState<
    { errorType: string; message: string }[] | null
  >(null)
  const [inputValues, setInputValues] = useState<{
    [index: string]: string | string[]
    selectedCheckboxes: string[]
  }>({ [emailId]: '', selectedCheckboxes: [] })

  const handleInputChange = (field: string, value: string) => {
    setInputValues({ ...inputValues, [field]: value })
  }

  const handleCheckboxes = (value: string) => {
    const newSelections: string[] = [...inputValues.selectedCheckboxes]
    const idx = newSelections.indexOf(value)

    if (idx > -1) {
      newSelections.splice(idx, 1)
    } else {
      newSelections.push(value)
    }

    setInputValues({ ...inputValues, selectedCheckboxes: newSelections })
  }

  // Check if error exists for input field or Hubspot errorType
  const checkErrorType = (fieldType?: string, errorType?: string) => {
    if (fieldType) {
      if (
        errorMessages?.find((err) =>
          err.message.includes(`'fields.${fieldType}'`),
        )
      ) {
        return true
      }
    }

    if (errorType) {
      if (errorMessages?.find((err) => err.errorType === errorType)) {
        return true
      }
    }

    return false
  }

  // Get inputs based on form type (ID)
  const renderHubspotInputs = () => {
    let inputs: JSX.Element | null = null
    switch (currentForm.id) {
      case guestSubscription.id:
      case guestSubscriptionTest.id:
        inputs = (
          <>
            <CheckboxList
              error={errorMessages && checkErrorType('subscription_type')}
              errorLabel="Please select at least one option."
              id={`blogCtaCheckboxList-${currentForm.id}`}
              onChange={(val: string) => handleCheckboxes(val)}
              selections={inputValues.selectedCheckboxes}
              values={currentForm.checkboxOptions?.map((option) => {
                return { label: option, disabled: false }
              })}
            />
            <FormInput
              error={errorMessages && checkErrorType('email')}
              errorLabel="Please complete this required field."
              name={emailId}
              onChange={handleInputChange}
              placeholder="Enter Your Email Here"
              type="text"
            />
          </>
        )
        break
      case ownerSubscription.id:
      case ownerSubscriptionTest.id:
      case checklistDownload.id:
      case successGuide.id:
      case marketPerformance.id:
      case suspectContent.id:
      case ownerInsight.id:
      case homeInventoryDownload.id:
      case vrIn2022Download.id:
      case sustainabilityDownload.id:
      case hvcoDownload.id:
      case mainentanceCheckListDownload.id:
        inputs = (
          <FormInput
            error={errorMessages && checkErrorType('email')}
            errorLabel="Please complete this required field."
            name={emailId}
            onChange={handleInputChange}
            placeholder="Enter Your Email Here"
            type="text"
          />
        )
        break
      default:
        break
    }

    return inputs
  }

  const handleHubspotSubmit: React.MouseEventHandler<
    HTMLButtonElement | HTMLAnchorElement
  > = async (e) => {
    e.preventDefault()
    handleCtaGtm(cta)

    const submitEl: any = e.currentTarget
    const hubspotCookie = getCookieByName('hubspotutk')

    // Build POST data
    const postBody: { [key: string]: any } = {
      submittedAt: Date.now(),
    }

    const subscriptionData = [
      { name: 'email', value: inputValues[emailId] },
      {
        name: 'lifecyclestage',
        value: 'subscriber',
      },
    ]

    // Add data based on form ID
    switch (currentForm.id) {
      case guestSubscription.id:
      case guestSubscriptionTest.id:
        postBody.fields = [
          ...subscriptionData,
          {
            name: 'subscription_type',
            // HubSpot enumeration fields are separated by semicolon
            value: inputValues.selectedCheckboxes.join(';'),
          },
        ]
        break
      case ownerSubscription.id:
      case ownerSubscriptionTest.id:
      case checklistDownload.id:
      case successGuide.id:
      case marketPerformance.id:
      case suspectContent.id:
      case ownerInsight.id:
      case sustainabilityDownload.id:
      case homeInventoryDownload.id:
      case vrIn2022Download.id:
      case hvcoDownload.id:
      case mainentanceCheckListDownload.id:
        postBody.fields = [...subscriptionData]
        break
      default:
        break
    }

    // Add UTM data if form has UTM fields
    if (searchParams && currentForm.hasUtm === true) {
      postBody.fields = [
        ...postBody.fields,
        ...Object.keys(searchParams).reduce(
          (utmArr: { [key: string]: any }[], searchParam) => {
            return searchParam.slice(0, 4) === 'utm_'
              ? [
                  ...utmArr,
                  ...[{ name: searchParam, value: searchParams[searchParam] }],
                ]
              : utmArr
          },
          [],
        ),
      ]
    }

    // Add HubSpot tracking
    if (hubspotCookie) {
      postBody.context = {
        hutk: hubspotCookie,
        pageUri: window.location.href,
        pageName: pageName,
      }
    }

    await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/${cta.form_config?.hubspot_config?.portal_id}/${currentForm.id}`,
      {
        method: 'POST',
        body: JSON.stringify(postBody),
        headers: { 'Content-Type': 'application/json' },
      },
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 'error' && data.errors.length) {
          setErrorMessages(data.errors)
        }
        if (data.inlineMessage) {
          setSuccessMessage(data.inlineMessage)
        }
      })
      .catch((err) => {
        console.error(`Error submitting HubSpot form: `, err)
      })

    if (submitEl.href) {
      handleCtaButtonLink(submitEl.href, cta)
    }
  }

  return cta.form_config.hubspot_config.portal_id &&
    currentForm.id &&
    currentForm.id !== 'form-not-found' ? (
    <CtaLayout
      checkErrorType={checkErrorType}
      cta={cta}
      fullWidth={fullWidth}
      handleSubmit={handleHubspotSubmit}
      hasInputs={
        currentForm.id !== qualifyButton.id ||
        currentForm.id !== qualifyButtonTest.id
      }
      renderInputs={renderHubspotInputs}
      showErrorMessage={errorMessages?.length ? true : false}
      showSuccessMessage={successMessage !== ''}
      successMessage={successMessage}
      utmParams={preserveAndApplyQuery('', searchParams)}
    />
  ) : null
}

export default HubspotCta
