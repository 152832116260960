import type { NextRouter } from 'next/router'

import { pushToDataLayer } from 'utils/Gtm'

import type { CtaData } from 'types/blogData'

export const getDisplayCategory = (
  categories: number[],
  formattedCategories: string[],
) => {
  if (formattedCategories && formattedCategories.length) {
    if (
      formattedCategories[0] === 'Featured' &&
      formattedCategories.length > 1
    ) {
      return {
        id: categories[1],
        name: formattedCategories[1],
      }
    }

    return {
      id: categories[0],
      name: formattedCategories[0],
    }
  }

  return {
    id: 49,
    name: 'Evolve',
  }
}

export const getCategoryLink = (categorySlug: string) => {
  return `/blog/${categorySlug}`
}

export const getCategorySlug = (
  categoryData: any,
  category: { id: number; name: string },
) => {
  if (categoryData && category) {
    return categoryData.find(
      (cat: { id: number; slug: string }) => category.id === cat.id,
    )?.slug
  }
  return ''
}

export const isOverviewPage = (
  pathname: string,
  query: NextRouter['query'],
) => {
  return pathname === 'blog' && !query.page && !query.search
}

export const handleCtaButtonLink = (link: string, cta: CtaData) => {
  window.open(
    link,
    cta.form_button?.open_button_link_in_new_tab ? '_blank' : '_self',
  )
}

export const getCtaIsFullWidth = (
  width: number,
  ctaCount: number,
  index: number,
) => {
  if (
    width > 800 &&
    (ctaCount === 1 ||
      (ctaCount > 2 && index % 2 === 0 && index === ctaCount - 1))
  ) {
    return true
  }
  return false
}

export const handleCtaGtm = (cta: CtaData) => {
  if (
    cta.data_layer_config?.data_layer_event &&
    cta.data_layer_config.data_layer_form_type
  ) {
    // push event to GTM data layer
    pushToDataLayer(cta.data_layer_config.data_layer_event, {
      formType: cta.data_layer_config.data_layer_form_type,
    })
  }
}

// Add UTM data to Formstack POST body
export const addUtmData = (
  searchParams: { [key: string]: any },
  formFields: { [key: string]: any },
  postBody: { [key: string]: any },
) => {
  return {
    ...postBody,
    ...Object.keys(searchParams).reduce((utmObj, searchParam) => {
      if (searchParam.slice(0, 4) === 'utm_') {
        utmObj = {
          ...utmObj,
          ...{ [formFields[searchParam]]: searchParams[searchParam] },
        }
      }
      return utmObj
    }, {}),
  }
}
