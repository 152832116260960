import React from 'react'
import Image from 'next/image'

import style from './BlogCta.module.scss'

import { stripWrappingParagraph } from 'utils/Strings'
import cloudinaryLoader from 'utils/imageLoaders/cloudinaryLoader'
import getWordpressCloudinaryId from 'utils/strings/getWordpressCloudinaryId'

import type { CtaData } from 'types/blogData'

type CtaLayoutProps = {
  cta: CtaData
  hasInputs: boolean
  fullWidth: boolean
  successMessage?: string
  showSuccessMessage?: boolean
  showErrorMessage?: boolean
  errorTypes?: string[]
  checkErrorType?: (...args: any[]) => any
  handleSubmit: (...args: any[]) => any
  renderInputs?: (...args: any[]) => any
  utmParams?: string
}

const CtaLayout: React.FC<CtaLayoutProps> = ({
  cta,
  hasInputs,
  fullWidth,
  successMessage,
  showSuccessMessage,
  showErrorMessage,
  errorTypes,
  checkErrorType,
  handleSubmit,
  renderInputs,
  utmParams,
}) => {
  const formSource = cta.form_config.form_source
  const formImage = cta.form_layout?.graphic ? (
    <Image
      alt=""
      className="image"
      height={cta.form_layout.graphic_position === 'bottom' ? 150 : 120}
      loader={cloudinaryLoader}
      src={getWordpressCloudinaryId(cta.form_layout?.graphic)!}
      style={{
        maxWidth: '100%',
        height: 'auto',
      }}
      width={cta.form_layout.graphic_position === 'bottom' ? 400 : 120}
    />
  ) : null

  const renderSuccessMessage = (message: string) => {
    if (formSource === 'hubspot') {
      return (
        <span
          className={style.successMessage}
          dangerouslySetInnerHTML={{
            __html: stripWrappingParagraph(message),
          }}
        />
      )
    }
    return <span className={style.successMessage}>{message}</span>
  }

  const renderErrorMessaging = () => {
    if (formSource === 'hubspot') {
      return (
        <span className={`formInputErrorMsg ${style.formError}`}>
          {checkErrorType && checkErrorType('', 'REQUIRED_FIELD')
            ? '*Please complete all required fields.'
            : 'Submissions are temporarily unavailable.'}
        </span>
      )
    } else if (formSource === 'formstack') {
      return (
        <span className={`formInputErrorMsg ${style.formError}`}>
          {errorTypes?.includes('submission')
            ? 'Submissions are temporarily unavailable.'
            : '*Please complete all required fields.'}
        </span>
      )
    }
    return null
  }

  const renderSubmit = () => {
    const buttonCopy = cta.form_button?.button_copy
      ? cta.form_button.button_copy
      : 'Submit'
    const buttonStyle = {
      background: cta.form_button?.button_background_color,
      color: cta.form_button?.button_text_color,
    }
    return (
      <div className={style.formButtonWrapper}>
        {cta.form_button?.button_link ? (
          // Create custom <a> because GTM btn-cta event only attaches to <a> tags
          <a
            className="btn-cta"
            href={`${cta.form_button.button_link}${
              utmParams ? `?${utmParams}` : ''
            }`}
            onClick={(e: any) => handleSubmit(e)}
            type="submit"
          >
            <span className={`${style.formButton}`} style={buttonStyle}>
              {buttonCopy}
            </span>
          </a>
        ) : (
          <button
            className={style.formButton}
            onClick={(e: any) => handleSubmit(e)}
            style={buttonStyle}
            type="submit"
          >
            {buttonCopy}
          </button>
        )}
      </div>
    )
  }

  return (
    <form
      className={`${style.ctaBody} ${fullWidth ? style.fullWidth : ''}  ${
        cta.form_copy?.form_copy_color === 'light'
          ? style.lightCopy
          : style.darkCopy
      }`}
      style={{
        backgroundImage: `${cta.form_layout?.form_background_color}`,
        borderBottom: `${
          cta.form_layout?.form_highlight_color
            ? `0.5rem solid ${cta.form_layout?.form_highlight_color}`
            : 'none'
        }`,
      }}
    >
      {/* Submission success message */}
      {showSuccessMessage && successMessage ? (
        renderSuccessMessage(successMessage)
      ) : (
        <>
          {/* Top image (full width form) */}
          {formImage &&
          fullWidth &&
          cta.form_layout?.graphic_position === 'top' ? (
            <div className={style.fullWidthFormTopImg}>{formImage}</div>
          ) : null}
          <div className={style.formContent}>
            {/* Label, header, body copy */}
            <div className={style.formCopy}>
              <span className={style.sectionLabel}>
                {cta.form_copy?.section_label}
              </span>
              <h2 className={style.mainHeader}>{cta.form_copy?.main_header}</h2>
              {cta.form_copy?.body_copy && (
                <div
                  className={style.bodyCopy}
                  dangerouslySetInnerHTML={{ __html: cta.form_copy?.body_copy }}
                />
              )}
            </div>
            {/* Top image (half width form) */}
            {formImage &&
            !fullWidth &&
            cta.form_layout?.graphic_position === 'top' ? (
              <div className={style.formTopImg}>{formImage}</div>
            ) : null}
            <div className={style.formBody}>
              {/* Input elements */}
              {hasInputs && (
                <div
                  className={`${style.formInputs} ${
                    cta.form_copy?.form_copy_color === 'light'
                      ? style.lightInputs
                      : style.darkInputs
                  }`}
                >
                  {renderInputs && renderInputs()}
                </div>
              )}
              {/* Legal disclaimer copy */}
              {cta.form_copy?.disclaimer_copy && (
                <span
                  className={`${style.disclaimerCopy} ${
                    cta.form_copy?.form_copy_color === 'light'
                      ? style.lightCopy
                      : style.darkCopy
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: cta.form_copy?.disclaimer_copy,
                  }}
                />
              )}
              {/* Submit element */}
              {renderSubmit()}
              {/* Error message */}
              {showErrorMessage && renderErrorMessaging()}
            </div>
          </div>
          {/* Bottom image */}
          {formImage && cta.form_layout?.graphic_position === 'bottom' ? (
            <div className={style.formBottomImg}>{formImage}</div>
          ) : null}
        </>
      )}
    </form>
  )
}
export default CtaLayout
