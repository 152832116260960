// Types
type HubspotForm = {
  id: string | null
  checkboxOptions?: string[]
  hasUtm: boolean
}
interface FormstackForm extends Omit<HubspotForm, 'hasUtm'> {
  fieldIds: { [key: string]: string }
}

export const postsPerPage = 12
export const featuredPostsLength = 4
export const allCategoryName = 'Blog Home'

// Store form IDs to verify what forms/inputs to show
export const hubspotForms: { [key: string]: HubspotForm } = {
  guestSubscription: {
    id: '7599d5eb-ab4d-4f0c-a8b4-5313a5e70256',
    checkboxOptions: ['Vacation Rental Owner Tips', 'Traveler Tips'],
    hasUtm: true,
  },
  guestSubscriptionTest: {
    id: 'fce092e2-d122-4675-83e1-af9a102459c5',
    checkboxOptions: ['Vacation Rental Owner Tips', 'Traveler Tips'],
    hasUtm: true,
  },
  ownerSubscription: {
    id: '2eabc7da-f823-4c50-bff7-b686a08adf36',
    hasUtm: false,
  },
  ownerSubscriptionTest: {
    id: '68bf076f-b763-41d6-9d03-57176ae65129',
    hasUtm: false,
  },
  qualifyButton: {
    id: 'dfaf43a3-15c0-4b65-9565-9389b1b459f1',
    hasUtm: false,
  },
  qualifyButtonTest: {
    id: '7506f6ce-57aa-44e2-949a-8558468c237f',
    hasUtm: false,
  },
  checklistDownload: {
    id: '737305ca-8f7e-497b-9b84-43b3c0791f13',
    hasUtm: true,
  },
  successGuide: {
    id: 'cbf26096-be02-4338-be28-7354e6a90a36',
    hasUtm: true,
  },
  marketPerformance: {
    id: 'afa80c28-5ede-4671-a9f4-d84a2b6d36a3',
    hasUtm: true,
  },
  suspectContent: {
    id: '5397b3a9-731e-4464-a114-f0244160becc',
    hasUtm: true,
  },
  ownerInsight: {
    id: 'b8be1c66-d076-454c-affa-9ca4239336a4',
    hasUtm: true,
  },
  nextStay: {
    id: '4d72a1ce-f925-486c-985f-7dd6c25f4a9e',
    hasUtm: false,
  },
  sustainabilityDownload: {
    id: 'f71fe652-fed8-4dc9-bcd7-676725f7d91f',
    hasUtm: true,
  },
  homeInventoryDownload: {
    id: '23ace7a2-2db0-4f4e-a90d-1dd1e1431463',
    hasUtm: true,
  },
  vrIn2022Download: {
    id: 'caa0a937-0407-4efc-8908-4b6963d158af',
    hasUtm: true,
  },
  hvcoDownload: {
    id: 'aa3fe83d-bcfe-4779-90b9-e040c16ee1c9',
    hasUtm: true,
  },
  mainentanceCheckListDownload: {
    id: '42bfe85f-c56e-493d-bfe4-133e92847317',
    hasUtm: true,
  },
}

// Formstack requires specific IDs for every form field
export const formstackForms: { [key: string]: FormstackForm } = {
  guestSubscription: {
    id: '4175409',
    fieldIds: {
      lifecycleStage: 'field_103049755',
      subscriptionType: 'field_103049877',
      email: 'field_103049756',
      utm_campaign: 'field_103049757',
      utm_medium: 'field_103049758',
      utm_content: 'field_103049759',
      utm_term: 'field_103049760',
      utm_source: 'field_103049761',
      utm_type: 'field_103049762',
      pageName: 'field_103049763',
    },
  },
  ownerGuestSubscription: {
    id: '4102381',
    fieldIds: {
      lifecycleStage: 'field_100497899',
      subscriptionType: 'field_100497915',
      email: 'field_100503930',
      utm_campaign: 'field_100503945',
      utm_medium: 'field_100503949',
      utm_content: 'field_100503948',
      utm_term: 'field_100503947',
      utm_source: 'field_100503946',
      utm_type: 'field_100503953',
      pageName: 'field_101262611',
    },
    checkboxOptions: ['Vacation Rental Owner Tips', 'Traveler Tips'],
  },
}
