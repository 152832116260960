import React, { useState, useMemo } from 'react'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import FormInput from 'components/Forms/FormInput'
import CheckboxList from 'components/Forms/CheckboxList'

import CtaLayout from './CtaLayout'

import { formstackForms } from 'config/Blog'

import { checkValidateEmail } from 'utils/validations'
import { handleCtaButtonLink, handleCtaGtm, addUtmData } from 'utils/Blog'
import { preserveAndApplyQuery } from 'utils/Strings'

import { CtaData } from 'types/blogData'

type FormstackCtaProps = {
  pageName: string
  cta: CtaData
  fullWidth: boolean
}

const FormstackCta: React.FC<FormstackCtaProps> = ({
  pageName,
  cta,
  fullWidth,
}) => {
  const router = useAugmentedRouter()
  const searchParams = router.query

  // Define individual forms for input/POST data checks
  const { ownerGuestSubscription, guestSubscription } = formstackForms

  // Find form to display
  const currentForm = useMemo(
    () =>
      formstackForms[
        Object.keys(formstackForms).find(
          (key) =>
            formstackForms[key].id ===
            cta.form_config?.formstack_config?.form_id,
        ) ?? ''
      ] ?? { id: 'form not found' },
    [cta.form_config?.formstack_config?.form_id],
  )

  // Create unique field id
  const emailId = `email-${currentForm.id}`

  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [errorTypes, setErrorTypes] = useState<string[]>([])
  const [inputValues, setInputValues] = useState<{
    [index: string]: string | string[]
    selectedCheckboxes: string[]
  }>({ [emailId]: '', selectedCheckboxes: [] })

  const handleInputChange = (field: string, value: string) => {
    setInputValues({ ...inputValues, [field]: value })
  }

  const handleCheckboxes = (value: string) => {
    const newSelections: string[] = [...inputValues.selectedCheckboxes]
    const idx = newSelections.indexOf(value)

    if (idx > -1) {
      newSelections.splice(idx, 1)
    } else {
      newSelections.push(value)
    }

    setInputValues({
      ...inputValues,
      selectedCheckboxes: newSelections,
    })
  }

  // Get inputs based on form type (ID)
  const renderFormstackInputs = () => {
    let inputs: JSX.Element | null = null
    switch (currentForm.id) {
      case ownerGuestSubscription.id:
        inputs = (
          <>
            <CheckboxList
              error={errorTypes.includes('selectedCheckboxes')}
              errorLabel="Please select at least one option."
              id={`blogCtaCheckboxList-${currentForm.id}`}
              onChange={(val: string) => handleCheckboxes(val)}
              selections={inputValues.selectedCheckboxes}
              values={currentForm.checkboxOptions?.map((option: string) => {
                return { label: option, disabled: false }
              })}
            />
            <FormInput
              error={errorTypes.includes(emailId)}
              errorLabel="Please complete this required field."
              name={emailId}
              onChange={handleInputChange}
              placeholder="Enter Your Email Here"
              type="text"
            />
          </>
        )
        break
      case guestSubscription.id:
        inputs = (
          <FormInput
            error={errorTypes.includes(emailId)}
            errorLabel="Please complete this required field."
            name={emailId}
            onChange={handleInputChange}
            placeholder="Enter Your Email Here"
            type="text"
          />
        )
        break
      default:
        break
    }

    return inputs
  }

  // Error handling
  const addErrorType = (type: string) => {
    if (!errorTypes.includes(type)) {
      setErrorTypes((errorTypes) => [...errorTypes, type])
    }
  }

  const removeErrorType = (type: string) => {
    setErrorTypes((errorTypes) => [...errorTypes.filter((msg) => msg !== type)])
  }

  const handleFieldValidity = (fields: string[]) => {
    let hasError = false
    fields.forEach((type) => {
      switch (type) {
        case emailId:
          if (!checkValidateEmail(inputValues[type].toString())) {
            addErrorType(type)
            hasError = true
          } else {
            removeErrorType(type)
          }
          break
        case 'selectedCheckboxes':
          if (!inputValues.selectedCheckboxes.length) {
            addErrorType(type)
            hasError = true
          } else {
            removeErrorType(type)
          }
          break
        default:
          break
      }
    })

    return hasError
  }

  const handleFormstackSubmit: React.MouseEventHandler<
    HTMLButtonElement | HTMLAnchorElement
  > = async (e) => {
    e.preventDefault()
    handleCtaGtm(cta)

    const submitEl: any = e.currentTarget

    if (errorTypes.includes('submission')) {
      removeErrorType('submission')
    }
    let hasError = false
    // Add POST data based on form ID
    let postBody: { [index: string]: any } = {}
    switch (currentForm.id) {
      case ownerGuestSubscription.id: {
        hasError = handleFieldValidity([emailId, 'selectedCheckboxes'])
        postBody = {
          [currentForm.fieldIds.email]: inputValues[emailId],
          [currentForm.fieldIds.lifecycleStage]: 'Subscriber',
          //  Checkbox field options are separated by newline
          [currentForm.fieldIds.subscriptionType]:
            inputValues.selectedCheckboxes.join('\n'),
          [currentForm.fieldIds.pageName]: pageName,
        }
        // Add UTM data
        if (searchParams) {
          postBody = addUtmData(searchParams, currentForm.fieldIds, postBody)
        }
        break
      }
      case guestSubscription.id: {
        hasError = handleFieldValidity([emailId])
        postBody = {
          [currentForm.fieldIds.lifecycleStage]: 'Subscriber',
          [currentForm.fieldIds.subscriptionType]: 'Traveler Tips',
          [currentForm.fieldIds.email]: inputValues[emailId],
          [currentForm.fieldIds.pageName]: pageName,
        }
        // Add UTM data
        if (searchParams) {
          postBody = addUtmData(searchParams, currentForm.fieldIds, postBody)
        }
        break
      }
      default:
        break
    }

    if (!hasError) {
      try {
        const res = await fetch(`/api/formstack/${currentForm.id}`, {
          method: 'POST',
          body: JSON.stringify(postBody),
          headers: process.env.NEXT_PUBLIC_ORIGIN?.includes('evrinternal.com')
            ? {
                Authorization:
                  'Basic ' +
                  btoa(
                    process.env.NEXT_PUBLIC_BASIC_AUTH_USER +
                      ':' +
                      process.env.NEXT_PUBLIC_BASIC_AUTH_PASSWORD,
                  ),
              }
            : undefined,
        })
        if (res.status === 201) {
          setSubmitSuccess(true)
        }
      } catch (err: any) {
        addErrorType('submission')
        console.error('Formstack submission error: ', err, err.response)
      }
    }

    if (submitEl.href) {
      handleCtaButtonLink(submitEl.href, cta)
    }
  }

  return currentForm.id && currentForm.id !== 'form not found' ? (
    <CtaLayout
      cta={cta}
      errorTypes={errorTypes}
      fullWidth={fullWidth}
      handleSubmit={handleFormstackSubmit}
      hasInputs={true}
      renderInputs={renderFormstackInputs}
      showErrorMessage={errorTypes.length > 0}
      showSuccessMessage={submitSuccess}
      successMessage={cta.form_config.formstack_config.submission_message}
      utmParams={preserveAndApplyQuery('', searchParams)}
    />
  ) : null
}

export default FormstackCta
