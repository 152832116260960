import React, { useState, createRef, useEffect, useCallback } from 'react'
import IntlTelInput from 'react-intl-tel-input'

import style from './FormInput.module.scss'
import 'react-intl-tel-input/dist/main.css'

interface Props {
  label?: React.ReactNode
  // 'desc' indicates 'description' represented as a textarea
  // This type signature preserves auto complete for our custom input types
  // and suggests you can use any valid input type as well
  type: (string & {}) | 'desc' | 'phone' | 'tel' | '<insert-input-type>'
  name: string
  error?: boolean | null
  errorLabel?: string | boolean
  onChange: (
    field: string,
    value: string,
    dialCode?: string,
    isValidPhone?: boolean,
  ) => void
  val?: string
  placeholder?: string
  half?: boolean
  success?: boolean | null
  successLabel?: string | boolean
  setBookingFormRefs?: (fn: (formRefs: any) => void) => void
}

const FormInput = (props: Props) => {
  const {
    label,
    type,
    name,
    error,
    errorLabel,
    onChange,
    val,
    placeholder,
    half,
    success,
    successLabel,
    setBookingFormRefs,
  } = props

  const [max, setMax] = useState()
  const inputRef = createRef<HTMLInputElement>()

  useEffect(() => {
    if (setBookingFormRefs && inputRef.current) {
      setBookingFormRefs((formRefs) => ({
        ...formRefs,
        [name]: inputRef.current,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // bool isValidPhone is result of react-intl-tel-input's validity checker
  const handleChange = useCallback(
    (isValidPhone, value, countryData) => {
      isValidPhone && setMax(value.length)
      onChange(name, value, countryData.dialCode, isValidPhone)
    },
    [name, onChange],
  )

  const clearInput = () => {
    onChange(name, '')
  }

  useEffect(() => {
    // Hack to make country code clickable.
    // See https://github.com/patw0929/react-intl-tel-input/issues/365
    if (inputRef.current?.className.includes('formInputPhone')) {
      const el = inputRef.current.querySelector('.selected-dial-code')
      if (el) {
        el.classList.remove('selected-dial-code')
      }
    }
  }, [inputRef])
  return (
    <div className={half ? style.formInputGroupHalf : style.formInputGroup}>
      <label className={style.formInputLabel} htmlFor={name}>
        {label}
      </label>
      {type !== 'desc' &&
        type !== 'phone' &&
        (val || val === '' ? (
          <input
            className={error ? style.formInputError : style.formInput}
            id={name}
            name={name}
            onChange={(e) => onChange(name, e.target.value)}
            placeholder={placeholder}
            ref={inputRef}
            type={type}
            value={val}
          />
        ) : (
          <input
            className={error ? style.formInputError : style.formInput}
            id={name}
            name={name}
            onChange={(e) => onChange(name, e.target.value)}
            placeholder={placeholder}
            ref={inputRef}
            type={type}
          />
        ))}
      {type === 'phone' &&
        (val || val === '' ? (
          <div
            className={error ? style.formInputPhoneError : style.formInputPhone}
            ref={inputRef}
          >
            <IntlTelInput
              autoComplete="tel"
              containerClassName="intl-tel-input"
              customPlaceholder={(selectedCountryPlaceholder) =>
                selectedCountryPlaceholder.replace(/\d/g, 'X')
              }
              defaultCountry="us"
              fieldId={name}
              fieldName="tel"
              format={true}
              formatOnInit={false}
              inputClassName={style.formInput}
              onPhoneNumberChange={handleChange}
              onSelectFlag={clearInput}
              separateDialCode={true}
              style={{ width: '100%' }}
              telInputProps={{ maxLength: max }}
              // @ts-ignore
              type="tel"
              value={val ? val : ''}
            />
          </div>
        ) : (
          <div
            className={error ? style.formInputPhoneError : style.formInputPhone}
            ref={inputRef}
          >
            <IntlTelInput
              autoComplete="tel"
              containerClassName="intl-tel-input"
              customPlaceholder={(selectedCountryPlaceholder) =>
                selectedCountryPlaceholder.replace(/\d/g, 'X')
              }
              defaultCountry="us"
              fieldId={name}
              fieldName="tel"
              format={true}
              formatOnInit={false}
              inputClassName={style.formInput}
              onPhoneNumberChange={handleChange}
              onSelectFlag={clearInput}
              separateDialCode={true}
              style={{ width: '100%' }}
              telInputProps={{ maxLength: max }}
              // @ts-ignore
              type="tel"
            />
          </div>
        ))}
      {type === 'desc' && (
        <textarea
          className={error ? style.formInputErrorLarge : style.formInputLarge}
          name={name}
          // @ts-ignore TODO: update to `currentTarget` when this is fully tested
          onInput={(e) => onChange(name, e.target.value)}
          ref={inputRef as any}
        ></textarea>
      )}
      {error && <span className="formInputErrorMsg">*{errorLabel}</span>}
      {success && (
        <span className={style.formInputSuccessMsg}>{successLabel}</span>
      )}
    </div>
  )
}

export default FormInput
