import React, { useCallback } from 'react'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import CustomLink from 'components/Link/CustomLink'

import style from './BlogSearchFilter.module.scss'

import { allCategoryName } from 'config/Blog'

import { isOverviewPage } from 'utils/Blog'

import SearchIcon from 'assets/icons/icon-search.svg'
import CloseIcon from 'assets/icons/icon-close.svg'

type BlogSearchFilterProps = {
  featuredCategoryData: any
  singleBlogPost?: boolean
  path: string
  searchInputVal: string
  setSearchInputVal: React.Dispatch<React.SetStateAction<string>>
  onSubmitSearch?: (query: string) => void
  setQuery?: React.Dispatch<React.SetStateAction<string>>
}

const BlogSearchFilter: React.FC<BlogSearchFilterProps> = ({
  featuredCategoryData,
  singleBlogPost = false,
  path,
  searchInputVal,
  setSearchInputVal,
  onSubmitSearch,
  setQuery,
}) => {
  const router = useAugmentedRouter()

  const isActiveCategory = (category) => {
    if (category === allCategoryName && isOverviewPage(path, router.query)) {
      return true
    }

    if (router.asPath.includes('/blog/')) {
      const slug = path

      if (slug && slug === category) {
        return true
      }
    }

    return false
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchInputVal(e.target.value)
  }

  const submitSearch = () => {
    if (onSubmitSearch) {
      onSubmitSearch(searchInputVal)
    } else {
      router.push({
        pathname: '/blog',
        query: {
          search: searchInputVal,
        },
      })
    }
  }

  const handleCategoryClick = useCallback(() => {
    setQuery?.('')
    setSearchInputVal('')
  }, [setQuery, setSearchInputVal])

  return (
    <header
      className={`${style.header} ${singleBlogPost ? style.headerPadding : ''}`}
    >
      <div className={style.search}>
        {searchInputVal && (
          <button
            className={style.clearSearch}
            onClick={() => {
              setSearchInputVal('')
              setQuery?.('')
            }}
          >
            <CloseIcon width={11} />
            <span>Clear Search</span>
          </button>
        )}
        <input
          onChange={handleChange}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
              ;(e.target as HTMLElement).blur()
              submitSearch()
            }
          }}
          placeholder="Search Blog"
          type="search"
          value={searchInputVal}
        />
        <button className={style.submitSearch} onClick={() => submitSearch()}>
          <SearchIcon height={14} width={14} />
          <span>Search</span>
        </button>
      </div>
      <div className={style.categories}>
        <ul>
          <li>
            <CustomLink
              className={
                isActiveCategory(allCategoryName) ? style.activeCategory : ''
              }
              href={'/blog'}
              onClick={handleCategoryClick}
              prefetch={false}
              title={allCategoryName}
            >
              {allCategoryName}
            </CustomLink>
          </li>
          {featuredCategoryData &&
            featuredCategoryData.map((category, i) => (
              <li key={i}>
                <CustomLink
                  className={
                    isActiveCategory(category.slug) ? style.activeCategory : ''
                  }
                  href={'/blog/' + category.slug}
                  onClick={handleCategoryClick}
                  prefetch={false}
                  title={category.name}
                >
                  {category.name}
                </CustomLink>
              </li>
            ))}
        </ul>
      </div>
    </header>
  )
}

export default BlogSearchFilter
