import React, { useState } from 'react'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import CtaLayout from './CtaLayout'

import { handleCtaButtonLink, handleCtaGtm } from 'utils/Blog'
import { preserveAndApplyQuery } from 'utils/Strings'

import type { CtaData } from 'types/blogData'

type InternalCtaProps = {
  cta: CtaData
  fullWidth: boolean
}

const InternalCta: React.FC<InternalCtaProps> = ({ cta, fullWidth }) => {
  const router = useAugmentedRouter()
  const searchParams = router.query
  const [successMessage, setSuccessMessage] = useState('')

  const handleInternalSubmit: React.MouseEventHandler<
    HTMLButtonElement | HTMLAnchorElement
  > = (e) => {
    e.preventDefault()
    const submitEl: any = e.currentTarget
    handleCtaGtm(cta)
    if (submitEl.href) {
      handleCtaButtonLink(submitEl.href, cta)
    }
    setSuccessMessage(cta.form_config.internal_config.submission_message)
  }

  return cta ? (
    <CtaLayout
      cta={cta}
      fullWidth={fullWidth}
      handleSubmit={handleInternalSubmit}
      hasInputs={false}
      showSuccessMessage={successMessage !== ''}
      successMessage={successMessage}
      utmParams={preserveAndApplyQuery('', searchParams)}
    />
  ) : null
}

export default InternalCta
